import React, { Component } from "react";
import Title from "../components/Header/HeaderTitlePage";
import "../css/index.css";
import "../css/titleStyle.css";
import "../css/taxe-judiciare-w3.css";

class TaxeJudiciare extends Component {
  render() {
    return (
      <React.Fragment>
        <Title
          title="Taxe si cheltuieli judiciare"
          page="Taxe Judiciare"
          breadcrumbs={true}
        />

        <div class="w3-row-padding w3-padding-64 w3-container">
          <div class="w3-content">
            <div class="w3-twothird">
              <h2 className="w3-text-red">Taxe judiciare de timbru</h2>
              <h6 class="w3-padding-32 text-justify">
                În conformitate cu prevederile art. 40 din OUG nr. 80/2013 din
                26 iunie 2013 privind taxele judiciare de timbru:<br></br>
                &emsp;"Taxele judiciare de timbru se plătesc de debitorul taxei
                în numerar, prin virament sau în sistem on-line, într-un cont
                distinct de venituri al bugetului local "Taxe judiciare de
                timbru şi alte taxe de timbru", al unităţii administrativ
                teritoriale în care persoana fizică are domiciliul sau reşedinţa
                ori, după caz, în care persoana juridică are sediul social.
                Costurile operaţiunilor de transfer al sumelor datorate ca taxă
                judiciară de timbru sunt în sarcina debitorului taxei.<br></br>
                &emsp;Dacă persoana care datorează taxa judiciară de timbru nu
                are nici domiciliul, nici reşedinţa ori, după caz, sediul în
                România, taxa judiciară de timbru se plăteşte în contul
                bugetului local al unităţii administrativ teritoriale în care se
                află sediul instanţei la care se introduce acţiunea sau
                cererea."
              </h6>

              <h5 class="w3-padding-16 w3-text-blue font-weight-bold">
                <h6>
                  Exemplu: &emsp;Pentru cei cu domiciliul sau sediul activitatii
                  in municipiul Cluj Napoca
                </h6>
                <br></br>
                &emsp;RO79 TREZ 216 21 070203 XXXXX TREZORERIA MUNICIPIULUI CLUJ
                NAPOCA<br></br>
                &emsp;COD FISCAL BENEFICIAR: 4305857<br></br>
                &emsp;Beneficiar : Municipiul Cluj Napoca<br></br>
              </h5>
            </div>

            <div class="w3-third w3-center">
              <i class="fa fa-anchor w3-padding-64 w3-text-red"></i>
            </div>
          </div>
        </div>

        <div class="w3-row-padding w3-light-grey w3-padding-64 w3-container">
          <div class="w3-content">
            <div class="w3-third w3-center">
              <i class="fa fa-coffee w3-padding-64 w3-text-red w3-margin-right"></i>
            </div>

            <div class="w3-twothird">
              <h2 className="w3-text-red"> Cheltuieli judiciare</h2>
              <h6 className="w3-padding-32 text-justify">
                Potrivit art.46 și art.49 ( 2 ) din OUG 80/2013 privind taxele
                judiciare de timbru: <br></br>
                &emsp; „Sumele realizate din cheltuielile judiciare avansate de
                stat din bugetele aprobate Ministerului Justiţiei şi
                Ministerului Public pentru desfăşurarea proceselor penale, care
                sunt suportate de părţi sau de alţi participanţi la proces, în
                condiţiile prevăzute de Codul de procedură penală, precum şi din
                amenzile judiciare se constituie venit la bugetul de stat şi se
                virează într-un cont distinct de venituri al acestuia.<br></br>
                &emsp; Acestea se pot achita la Agenţiile de Administrare
                Fiscală în a căror rază teritorială îşi are domiciliul fiscal
                debitorul, potrivit legislaţiei privind executarea silită a
                creanţelor fiscale.<br></br>
                &emsp; Obligaţia de plată a cheltuielilor judiciare către stat
                constituie creanţă fiscală. Dispozitivul hotărârii, cuprinzând
                obligaţia de plată către stat a sumelor avansate din bugetul
                statului, constituie titlu executoriu şi se comunică de îndată
                organelor competente.”<br></br>
                <br></br>
                Potrivit art. 31 din Legea nr. 207/2015 privind Codul de
                procedură fiscală:<br></br>
                &emsp; „(1) În cazul creanţelor fiscale administrate de organul
                fiscal central, prin domiciliu fiscal se înţelege:<br></br>
                a) pentru persoanele fizice, adresa unde îşi au domiciliul,
                potrivit legii, sau adresa unde locuiesc efectiv, în cazul în
                care aceasta este diferită de domiciliu;<br></br>
                b) pentru persoanele fizice care desfăşoară activităţi economice
                în mod independent sau exercită profesii libere, sediul
                activităţii sau locul unde se desfăşoară efectiv activitatea
                principală;<br></br>
                c) pentru persoanele juridice, sediul social sau locul unde se
                exercită gestiunea administrativă şi conducerea efectivă a
                afacerilor, în cazul în care acestea nu se realizează la sediul
                social declarat;<br></br>
                d) pentru asocierile şi alte entităţi fără personalitate
                juridică, sediul acestora sau locul unde se desfăşoară efectiv
                activitatea principală.”
              </h6>
              <h5 class="w3-padding-16 w3-text-blue font-weight-bold">
                <h6>
                  Exemplu: <br></br>Pentru cei cu domiciliul sau sediul
                  activitatii in municipiul Cluj Napoca
                </h6>
                <br></br>
                &emsp;RO77TREZ21620A332501XXXX - Venituri din recuperarea
                cheltuielilor judiciare avansate de stat de la persoanele
                rezidente<br></br>
                &emsp;RO24TREZ21620A332502XXXX - Venituri din recuperarea
                cheltuielilor judiciare avansate de stat de la persoanele
                nerezidente
              </h5>
            </div>
          </div>
        </div>

        <div class="w3-row-padding w3-padding-64 w3-container">
          <div class="w3-content">
            <div class="w3-twothird">
              <h2 className="w3-text-red ">Cauţiune</h2>
              <h6 className="w3-padding-32 text-justify">
                În conformitate cu prevederile art. 671 și art. 1057 din Legea
                nr. 134/2010 din 1 iulie 2010 *** Republicată privind Codul de
                procedură civilă:<br></br>
                <br></br>
                &emsp; "Depunerea şi consemnarea de cauţiuni sau alte sume
                <br></br>
                <br></br>
                &emsp;&emsp; (1) Depunerea sau consemnarea oricărei sume în
                scopul participării la desfăşurarea, potrivit legii, a
                executării silite ori al obţinerii suspendării executării
                silite, depunerea sumelor cu afectaţiune specială, precum şi
                depunerea sau consemnarea sumelor reprezentând veniturile
                bunurilor urmărite ori preţul rezultat din vânzarea acestor
                bunuri se fac la CEC Bank - S.A., Trezoreria Statului sau la
                orice altă instituţie de credit care are în obiectul de
                activitate operaţiuni de consemnare la dispoziţia instanţei de
                executare sau a executorului judecătoresc.<br></br>
                &emsp;&emsp; (2) Dovada depunerii sau consemnării acestor sume
                se poate face cu recipisa de consemnare sau cu orice alt înscris
                admis de lege.<br></br>
                &emsp;&emsp; (3) Eliberarea acestor sume se face persoanelor
                îndreptăţite sau reprezentanţilor acestora numai pe baza
                dispoziţiei executorului judecătoresc ori a instanţei de
                executare, după caz.<br></br>
                &emsp;&emsp; (4) Dispoziţiile art. 1.057 şi următoarele
                privitoare la cauţiunea judiciară se aplică în mod
                corespunzător.”<br></br>
                <br></br>
                &emsp;&emsp;&emsp;&emsp;&emsp;ART.1057: <br></br>
                <br></br>&emsp;"Stabilirea cauţiunii şi depunerea ei<br></br>
                &emsp;&emsp; (1) Când legea prevede darea unei cauţiuni, suma
                datorată de parte cu acest titlu se stabileşte de către instanţă
                în condiţiile legii şi se depune la Trezoreria Statului, la CEC
                Bank - S.A. sau la orice altă instituţie de credit care
                efectuează astfel de operaţiuni, pe numele părţii respective, la
                dispoziţia instanţei sau, după caz, a executorului
                judecătoresc."
              </h6>
              <h5 class="w3-padding-16 w3-text-blue font-weight-bold">
                <h6>
                  Numărul de cont pus la dispoziţie de CEC BANK pentru
                  consemnarea unei cauţiuni este:<br></br>
                  <br></br>
                </h6>
                &emsp;RO46CECECJ0103RON0000001<br></br>
                &emsp;Cod identificare fiscal TRIBUNALUL CLUJ – 4565300
                <br></br>&emsp;Titular cont: CEC BANK SA
              </h5>
            </div>
            <div class="w3-third w3-center">
              <i class="fa fa-anchor w3-padding-64 w3-text-red"></i>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TaxeJudiciare;
