import React from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/homestyle.css';
import InfiniteScroll from "react-infinite-scroll-component";

class News extends React.Component {

    state = {
        items: Array.from({ length: 20 }),
        hasMore: true,
        anunturi: [{
            text: "",
            link: "",
            pagina: ""
        }]
    };

    fetchMoreData = () => {
        if (this.state.anunturi.length >= 3) {
            this.setState({ hasMore: false });
            return;
        }
        // a fake async api call like which sends
        // 20 more records in .5 secs
        //setTimeout(() => {
        //    this.setState({
        //        anunturi: this.state.anunturi.concat(this.state.anunturi)
        //    });
        //}, 500);
    };

    componentWillMount() {

        fetch("/anunturiJson")
            .then((response) => response.json())
            .then((findresponse) =>
                this.setState({
                    anunturi: findresponse.anunturi
                })
            )
    }

    render() {
        return (
            <div class="single_sidebar py-4">
                <h2 className=" titleClass">Anunțuri</h2>
                <InfiniteScroll
                    name="Anunțuri"
                    dataLength={this.state.items.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    height={320}
                    endMessage={
                        <p style={{ fontWeight: "bold", textAlign: "center" }}>
                            Ați vizualizat toate anunțurile noi.
                        </p>
                    }
                >
                    {this.state.anunturi.map((i, index) => (
                        <div class="media wow fadeInDown">
                            <div class="media-body font-weight-bold">
                                <a href={i.link} class="catg_title font-weight-normal text-indent">&emsp; {i.text}.<b> Click aici pentru detalii.</b><hr /></a>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        );

    }
}

export default News


