import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
    Nav,
    NavItem,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    NavLink,
    NavbarBrand,
    Collapse,
    NavbarToggler,
    Navbar
} from "reactstrap";


class NavBarComp extends Component {
    constructor(props) {
        super(props);


        this.toggleInstanta = this.toggleInstanta.bind(this);
        this.toggleInformatii = this.toggleInformatii.bind(this);
        this.toggleInstante = this.toggleInstante.bind(this);
        this.toggleUrgente = this.toggleUrgente.bind(this);
        this.toggleJurisprudenta = this.toggleJurisprudenta.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleOrganizare = this.toggleOrganizare.bind(this);

        this.onMouseEnterInstanta = this.onMouseEnterInstanta.bind(this);
        this.onMouseLeaveInstanta = this.onMouseLeaveInstanta.bind(this);
        this.onMouseEnterInformatii = this.onMouseEnterInformatii.bind(this);
        this.onMouseLeaveInformatii = this.onMouseLeaveInformatii.bind(this);
        this.onMouseEnterInstante = this.onMouseEnterInstante.bind(this);
        this.onMouseLeaveInstante = this.onMouseLeaveInstante.bind(this);
        this.onMouseEnterOrganizare = this.onMouseEnterOrganizare.bind(this);
        this.onMouseLeaveOrganizare = this.onMouseLeaveOrganizare.bind(this);
        this.onMouseLeaveUrgente = this.onMouseLeaveUrgente.bind(this);
        this.onMouseEnterUrgente = this.onMouseEnterUrgente.bind(this);
        this.onMouseLeaveJurisprudenta = this.onMouseLeaveJurisprudenta.bind(this);
        this.onMouseEnterJurisprudenta = this.onMouseEnterJurisprudenta.bind(this);
        this.state = {
            dropdownInstanta: false,
            dropdownInformatii: false,
            dropdownInstante: false,
            dropdownUrgente: false,
            dropdownJurisprudenta: false,
            collapsed: true,
        };
    }

    /** Functions for Dropdown  
        "Despre Institutie" 
        toggle - for open close (no nedded anymore)?!
        onMouseEnter - to open Dropdown Menu on hover 
        onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterInstanta() {
        this.setState({ dropdownInstanta: true });
    }
    onMouseLeaveInstanta() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownInstanta: false });
        }.bind(this), 500);

    }
    toggleInstanta() {
        this.setState({
            dropdownInstanta: !this.state.dropdownInstanta
        });
    }

    /** Functions for Dropdown "Informatii"  
      * toggle - for open close (no nedded anymore)?!
      * onMouseEnter - to open Dropdown Menu on hover 
      * onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterInformatii() {
        this.setState({ dropdownInformatii: true });
    }
    onMouseLeaveInformatii() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownInformatii: false });
        }.bind(this), 500);


    }
    toggleInformatii() {
        this.setState({
            dropdownInformatii: !this.state.dropdownInformatii
        });
    }

    /** Functions for Dropdown "Instante"  
      * toggle - for open close (no nedded anymore)?!
      * onMouseEnter - to open Dropdown Menu on hover 
      * onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterInstante() {
        this.setState({ dropdownInstante: true });
    }
    onMouseLeaveInstante() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownInstante: false });
        }.bind(this), 500);
    }
    toggleInstante() {
        this.setState({
            dropdownInstante: !this.state.dropdownInstante
        });
    }

    /** Functions for Dropdown "Urgente"  
      * toggle - for open close (no nedded anymore)?!
      * onMouseEnter - to open Dropdown Menu on hover 
      * onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterUrgente() {
        this.setState({ dropdownUrgente: true });
    }
    onMouseLeaveUrgente() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownUrgente: false });
        }.bind(this), 500);
    }

    toggleUrgente() {
        this.setState({
            dropdownUrgente: !this.state.dropdownUrgente
        });
    }



      /** Functions for Dropdown "Jurisprudenta"  
      * toggle - for open close (no nedded anymore)?!
      * onMouseEnter - to open Dropdown Menu on hover 
      * onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterJurisprudenta() {
        this.setState({ dropdownJurisprudenta: true });
    }
    onMouseLeaveJurisprudenta() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownJurisprudenta: false });
        }.bind(this), 500);
    }

    toggleJurisprudenta() {
        this.setState({
            dropdownJurisprudenta: !this.state.dropdownJurisprudenta
        });
    }



    /** Functions for Dropdown "Organizare"  
      * toggle - for open close (no nedded anymore)?!
      * onMouseEnter - to open Dropdown Menu on hover 
      * onMouseLeave - to close Dropdown menu on hover
     **/
    onMouseEnterOrganizare() {
        this.setState({ dropdownOrganizare: true });
    }
    onMouseLeaveOrganizare() {
        setTimeout(function () { //Start the timer
            this.setState({ dropdownOrganizare: false });
        }.bind(this), 500);
    }
    toggleOrganizare() {
        this.setState({
            dropdownOrganizare: !this.state.dropdownOrganizare
        });
    }


    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    closeNavbar() {
        if (this.state.collapsed !== true) {
            this.toggleNavbar();
        }
    }
    render() {
        return (
            <section id="55" className="bg-light py-3">
                    <h1 style={{fontFamily:"Monotype Corsiva", padding:"10px", fontSize: "44px", textAlign: "center"}}><img style={{ paddingTop:'10px' }}src={require("../../images/mjLogo.png")} alt="mj" />Tribunalul Cluj</h1>
                <Navbar className="text-nowrap navbar-light bg-light" expand="lg">
               
               

               <NavbarToggler onClick={this.toggleNavbar} ></NavbarToggler>
               <Collapse isOpen={!this.state.collapsed} navbar >

                   <Nav className="m-auto" navbar >
                       <NavItem className="mr-2">
                           <NavLink className="font-weight-bold" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/`} >Acasă</NavLink>
                       </NavItem>
                       <Dropdown isOpen={this.state.dropdownInstanta} toggle={this.toggleInstanta}>

                           <DropdownToggle className="font-weight-bold" nav caret> Despre Instituţie</DropdownToggle>
                           <DropdownMenu style={{fontSize: "18px",fontFamily:"Arial"}} >
                               <DropdownItem 
                               onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/prezentare`}>Prezentare</DropdownItem>
                               <Dropdown
                                   className="px-4"
                                   nav
                                   direction='right'
                                   isOpen={this.state.dropdownOrganizare}
                                   toggle={this.toggleOrganizare}
                                       /* onMouseOver={this.onMouseEnterOrganizare} onMouseLeave={this.onMouseLeaveOrganizare} */>

                                   <DropdownToggle nav caret style={{ color: 'black', mrgin: '0px', padding: '0px' }}>Organizare</DropdownToggle>

                                   <DropdownMenu style={{fontSize: "18px",fontFamily:"Arial",border: 0}}>
                                       <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/conducere`}>Conducere</DropdownItem>
                                       <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/organizare`}>Structură organizatorică</DropdownItem>
                                   </DropdownMenu>
                               </Dropdown>

                               <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/competente`}>Competenţe şi Circumscripţie</DropdownItem>
                               <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/rapoarte`}>Instaţa în cifre</DropdownItem>
                           </DropdownMenu>
                       </Dropdown>

                       <Dropdown nav
                           isOpen={this.state.dropdownInformatii}
                           toggle={this.toggleInformatii}
                       /*  onMouseOver={this.onMouseEnterInformatii} onMouseLeave={this.onMouseLeaveInformatii} */
                       >
                           <DropdownToggle nav caret className="font-weight-bold">
                               Informaţii publice
                               </DropdownToggle>
                           <DropdownMenu style={{fontSize: "18px",fontFamily:"Arial"}}>
                               <DropdownItem onClick={this.closeNavbar} href="https://www.curteadeapelcluj.ro/index.php/anunturi/concursuri.html?month=jan&yr=2020">Concursuri</DropdownItem>
                               <DropdownItem className="font-weight-bold" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/infoPublice`} >Informaţii de interes public</DropdownItem>
                               <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/declaratii`}>Declaraţii</DropdownItem>
                               <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/comunicate`}>Comunicate de presă</DropdownItem>
                               <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/hotarari`}>Hotărâri de colegiu</DropdownItem>
                           </DropdownMenu>
                       </Dropdown>
                       <NavItem className="font-weight-bold">
                           <NavLink href="https://www.curteadeapelcluj.ro/index.php/dosare/info-dosar.html">Info-Dosar</NavLink>
                       </NavItem>

                       <Dropdown nav
                           isOpen={this.state.dropdownInstante}
                           toggle={this.toggleInstante}
                       /*  onMouseOver={this.onMouseEnterInstante} onMouseLeave={this.onMouseLeaveInstante} */
                       >
                           <DropdownToggle className="font-weight-bold" nav caret>Instanţe</DropdownToggle>
                           <DropdownMenu style={{fontSize: "18px",fontFamily:"Arial"}}>
                               <DropdownItem href="http://portal.just.ro/211/SitePages/acasa_default.aspx?id_inst=211">Judecătoria Cluj-Napoca</DropdownItem>
                               <DropdownItem href="http://portal.just.ro/219/SitePages/acasa_default.aspx?id_inst=219">Judecătoria Dej</DropdownItem>
                               <DropdownItem href="http://portal.just.ro/242/SitePages/acasa_default.aspx?id_inst=242">Judecătoria Huedin</DropdownItem>
                               <DropdownItem href="http://portal.just.ro/235/SitePages/acasa_default.aspx?id_inst=235">Judecătoria Gherla</DropdownItem>
                               <DropdownItem href="http://portal.just.ro/328/SitePages/acasa_default.aspx?id_inst=328">Judecătoria Turda</DropdownItem>
                           </DropdownMenu>
                       </Dropdown>
                       
                       <Dropdown isOpen={this.state.dropdownUrgente} toggle={this.toggleUrgente}>
                        <DropdownToggle className="font-weight-bold " nav caret >Liste de şedinţe</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={this.closeNavbar} href="http://portal.just.ro/117/SitePages/Lista_Sedinte.aspx?id_inst=117">Liste sedinte - Portalul Instantelor</DropdownItem>
                        </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={this.state.dropdownJurisprudenta} toggle={this.toggleJurisprudenta}>
                        <DropdownToggle className="font-weight-bold " nav caret >Jurisprudenţă</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaCivila`}>Secția Civilă</DropdownItem>
                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaPenala`}> Secția Penală</DropdownItem>
                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaMixta`}> Secția mixtă de contencios și administrativ</DropdownItem> 
                        </DropdownMenu>
                        </Dropdown>

                       <NavItem >
                           <NavLink className="font-weight-bold" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink>
                       </NavItem>

                       <NavItem>
                <NavLink
                  className='font-weight-bold'
                  onClick={this.closeNavbar}
                  href='https://docs.google.com/forms/d/e/1FAIpQLSc_UTUkn2o8jA5rcrgvIHVxkJtG7FrEfLnMnMa6I4qXkMk2Qg/viewform?pli=1'
                  target='_blank'
                >
                  Chestionar
                </NavLink>
              </NavItem>
                   </Nav>
               </Collapse>
           </Navbar>
            </section>
           
        );
    }
}

export default NavBarComp;

