import React, { Component } from 'react';
import Title from '../components/Header/HeaderTitlePage';
import Divider from '@material-ui/core/Divider';
import Anunt from '../components/anunt';
import axios from 'axios';
import List from '@material-ui/core/List';

class Jurisprudenta extends Component {
  state = {
    sectiaCivila: [],
    sectiaPenala: [],
    sectiaMixta: []

  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    /* Sectia Civila*/
    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/Jurisprudenta/Sectia Civila"
        }
      })
      .then(res => {

        /* Foreach folder from res, requeat all pdf files  */
        let i = res.data.length - 1;
        for (i; i >= 0; i--) {
          let year = res.data[i];
          axios
            .get(`/getFiles`, {
              params: {
                folderName: "PDFs/Jurisprudenta/Sectia Civila/" + year
              }
            })
            .then(res2 => {
              console.log(res2);
              let m = res2.data.length - 1;
              let object2;
              let object3 = [];

              /* Extract data field from pdf filename */
              for (m; m >= 0; m--) {
                let indexStart = res2.data[m].indexOf('-');
                let indexStop = res2.data[m].indexOf('.pdf');
                let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
                let titluDoc = res2.data[m].substring(0, indexStop);

                object2 = Object.assign({ titlu: titluDoc, data: dataDoc, locatie: "Jurisprudenta/Sectia Civila/" + year + "/" + res2.data[m] });
                object3.push(object2);
              }

              /* Create an object[{folder,docs[]}] where:
               * folder  - folder name 
               * docs - list of Pdf files from folder
               *  
               * Add created object to {this.state.folders}
               */
              object3.sort((a, b) => new Date(b.date) - new Date(a.date));
              object3.reverse();
              const object = Object.assign({ folder: year, docs: object3 });
              //var stateCopy = Object.assign({}, this.state);
              //stateCopy.sectii[0].folders = object;
              //this.setState(stateCopy);

              this.setState({ sectiaCivila: [...this.state.sectiaCivila, object] });
            })
            .catch(e => {
              console.log("Eroare la deschiderea fișierului", e);
            });
        }
      })
      .catch(e => {
        console.log("Eroare la deschiderea folderului", e);
      });


    /* Sectia Penală*/
    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/Jurisprudenta/Sectia Penala"
        }
      })
      .then(res => {

        /* Foreach folder from res, requeat all pdf files  */
        let i = res.data.length - 1;
        for (i; i >= 0; i--) {
          let year = res.data[i];
          axios
            .get(`/getFiles`, {
              params: {
                folderName: "PDFs/Jurisprudenta/Sectia Penala/" + year
              }
            })
            .then(res2 => {

              let m = res2.data.length - 1;
              let object2;
              let object3 = [];

              /* Extract data field from pdf filename */
              for (m; m >= 0; m--) {
                let indexStart = res2.data[m].indexOf('-');
                let indexStop = res2.data[m].indexOf('.pdf');
                let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
                let titluDoc = res2.data[m].substring(0, indexStop);

                object2 = Object.assign({ titlu: titluDoc, data: dataDoc, locatie: "Jurisprudenta/Sectia Penala/" + year + "/" + res2.data[m] });
                object3.push(object2);
              }

              /* Create an object[{folder,docs[]}] where:
               * folder  - folder name 
               * docs - list of Pdf files from folder
               *  
               * Add created object to {this.state.folders}
               */
              object3.sort((a, b) => new Date(b.date) - new Date(a.date));
              object3.reverse();
              const object = Object.assign({ folder: year, docs: object3 });
              this.setState({ sectiaPenala: [...this.state.sectiaPenala, object] });
            })
            .catch(e => {
              console.log("Eroare la deschiderea fișierului", e);
            });
        }
      })
      .catch(e => {
        console.log("Eroare la deschiderea folderului", e);
      });




    /* Secția de contencios și administrativ*/
    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/Jurisprudenta/Sectia mixta"
        }
      })
      .then(res => {

        /* Foreach folder from res, requeat all pdf files  */
        let i = res.data.length - 1;
        for (i; i >= 0; i--) {
          let year = res.data[i];
          axios
            .get(`/getFiles`, {
              params: {
                folderName: "PDFs/Jurisprudenta/Sectia mixta/" + year
              }
            })
            .then(res2 => {

              let m = res2.data.length - 1;
              let object2;
              let object3 = [];

              /* Extract data field from pdf filename */
              for (m; m >= 0; m--) {
                let indexStart = res2.data[m].indexOf('-');
                let indexStop = res2.data[m].indexOf('.pdf');
                let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
                let titluDoc = res2.data[m].substring(0, indexStop);

                object2 = Object.assign({ titlu: titluDoc, data: dataDoc, locatie: "Jurisprudenta/Sectia mixta/" + year + "/" + res2.data[m] });
                object3.push(object2);
              }

              /* Create an object[{folder,docs[]}] where:
               * folder  - folder name 
               * docs - list of Pdf files from folder
               *  
               * Add created object to {this.state.folders}
               */
              object3.sort((a, b) => new Date(b.date) - new Date(a.date));
              object3.reverse();
              const object = Object.assign({ folder: year, docs: object3 });

              this.setState({ sectiaMixta: [...this.state.sectiaMixta, object] });
            })
            .catch(e => {
              console.log("Eroare la deschiderea fișierului", e);
            });
        }
      })
      .catch(e => {
        console.log("Eroare la deschiderea folderului", e);
      });
  }



  render() {
    return (
      <React.Fragment>
        <Title
          title="Jurisprudența Tribunalului Cluj"
          page="Jurisprudență"
          breadcrumbs={true}
        />

        <div className="my-5 container">
          <h6 className="py-3" variant="body1">
            {" "}
            &emsp;În această pagină au fost incluse hotărâri relevante din practica secţiilor Tribunalului Cluj, destinate atât specialiştilor în drept, cât şi cetăţenilor interesaţi de modul în care au fost rezolvate anumite cazuri în instanţa noastră.
          </h6>
          <Divider />


          {/* Create a List 
            * Add each folder and docs in List using component <Anunt/>
            */}
          {this.state.sectiaCivila.map((sectie, index) => {
            return (
              <div key={index}>
                <h5><b>Secția Civilă</b></h5>
                <List component="nav">

                  <Anunt
                    icon={require("../images/icons/law2.png")}
                    altText={"jurisprudenta"}
                    titlu={sectie.folder}
                    docs={sectie.docs}
                    expanded={true}
                  />

                </List>
              </div>
            );
          })}

          {this.state.sectiaMixta.map((sectie, index) => {
            return (
              <div key={index}>
                <h5><b>Secția mixtă de contencios, administrativ și fiscal</b></h5>
                <List component="nav">

                  <Anunt
                    icon={require("../images/icons/law2.png")}
                    altText={"jurisprudenta"}
                    titlu={sectie.folder}
                    docs={sectie.docs}
                    expanded={true}
                  />

                </List>
              </div>
            );
          })}

          {this.state.sectiaPenala.map((sectie, index) => {
            return (
              <div key={index}>
                <h5><b>Secția Penală</b></h5>
                <List component="nav">

                  <Anunt
                    icon={require("../images/icons/law2.png")}
                    altText={"jurisprudenta"}
                    titlu={sectie.folder}
                    docs={sectie.docs}
                    expanded={true}
                  />

                </List>
              </div>
            );
          })}
        </div>
      </React.Fragment >
    );
  }
}

export default Jurisprudenta;
