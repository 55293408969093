import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Title from '../components/Header/HeaderTitlePage';
import Anunt from "../components/anunt";
import axios from 'axios';
import InfoList from '../components/infoPubliceList';


class InfoPublice extends Component {
    state = {
        items: [],
        folders: [],
        informatiiPublice:[{
                titlu: "",
                text: "",
                folder: "",
                listOfDocs: []
            }]
    }

    componentWillMount(){
        
        fetch("/infoPubliceJson")
        .then((response) => response.json())
        .then((findresponse)=> 
                this.setState({
                    informatiiPublice : findresponse.informatiiPublice
                })
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        axios
            .get(`/getFiles`, {
                params: {
                    folderName: "PDFs/Informatii publice"
                }
            })
            .then(res => {
                let i = 0;
                
                /* Foreach folder from res, requeat all pdf files  */
                for (i = 0; i < res.data.length; i++) {
                    let year = res.data[i];

                    axios
                        .get(`/getFilesNameOrberByDate`, {
                            params: {
                                folderName: "PDFs/Informatii publice/" + year
                            }
                        })
                        .then(res2 => {
                            let count = 0;
                            let object2;
                            let object3 = [];

                            /* Create a list of objects with files */
                            for (count = 0; count < res2.data.length; count++) {
                                object2 = Object.assign({ titlu: res2.data[count], locatie: "Informatii publice/" + year + "/" + res2.data[count] });
                                object3.push(object2);
                            }

                            /** Add created object to this.state */
                            this.state.informatiiPublice.map((item)=>{
                                if(item.folder === year){
                                     item.listOfDocs.push.apply(item.listOfDocs,object3); 
                                }
                                return true;
                            })
                            this.setState({folders: []});
                            
                            
                        })
                        .catch(e => {
                            console.log("Eroare la deschiderea fișierului", e);
                        });
                }
            })
            .catch(e => {
                console.log("Eroare la deschiderea fișierului", e);
            });
    }

    componentWillUnmount(){
        this.state.informatiiPublice.map((item)=>{
            item.listOfDocs = [];
            return true;
        })
    }

    render() {
        const listaFinala = this.state.informatiiPublice.map((item, index) => {
            if(item.folder !== "")
            {
                return (
                    <Anunt
                    key={index}
                    icon={require("../images/icons/law2.png")}
                    altText={"hotarari"}
                    titlu={item.titlu}
                    text={item.text}
                    docs={item.listOfDocs}
                    expanded={true}
                    />
                );
            }
            else{
                return (
                    <InfoList index={index}
                        titlu={item.titlu}
                        text={item.text}
                    />
                )
            }
           
            });

        return (
            <React.Fragment>
                <Title
                    title="Informații Publice"
                    page="Informații Publice"
                    subpage="Informații"
                    breadcrumbs={true}
                />

                <div className="my-5 container">
                    <List component="nav">
                        {listaFinala}
                    </List>
                </div>
            </React.Fragment>
        );
    }
}

export default InfoPublice;
           