import React, { Component } from 'react';
import axios from 'axios';/* 
import { Button } from 'reactstrap'; */
import Button from '@material-ui/core/Button';
import Pdf from '../Pdf/pdf';
import { withRouter } from 'react-router-dom';

class CardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blob: [],
            openFile: false,
        };
    }

    /* Closing the modal containing pdf file */
    onCloseModal = () => {
        this.setState({
            openFile: false,
            blob: []
        });
    };

    routeChange = () => {
        let path = this.props.locatie;
        this.props.history.push(path);
    }

    handleClickToOpen = () => {
        const { locatie, index } = this.props;
        if (index === 0  || index === 1  || index === 2 || index === 3 || index ===4 || index === 5) {
            this.routeChange();
        } else if (locatie.substring(0, 4) === "http") {
            window.open(locatie)
        }
        else {
            axios
                .get(`/getFileContent`, {
                    params: {
                        fileName: locatie
                    }
                })
                .then(res => {
                     this.setState({
                        blob: res.data,
                        openFile: true
                    })
                })
                .catch(e => {
                    console.log("Eroare la deschiderea fișierului", e);
                });

        }

    }

    render() {
        const { blob} = this.state;
        const { locatie, index, icon, title } = this.props;

        return (
            <React.Fragment>
                <Button
                    key={index}
                    onClick={() => this.handleClickToOpen()}
                    style={{ outline: 'none', backgroundColor: 'transparent', border: '0px', borderColor: 'transparent', color: '#122034', fontWeight: 'bold' }}
                >
                    <div className="single-list-topics-content rounded shadow">

                        {/* Icon comes from utils/constants.js file using props
                          * Titlu - text shown on infoUtile's card
                          * and open document for each of them 
                          */}
                        <div className="single-list-topics-icon">
                            <img style={{ color: '#122034' }} className="mb-2" src={icon} alt="Icon_Document" />
                        </div>
                        <p style={{fontSize: 14}}>
                            {title}
                        </p>
                     
                    </div>
                </Button>
                {this.state.openFile
                    ? <Pdf
                        data={`data:application/pdf;base64,${blob}`}
                        print={blob}
                        handleCloseModal={this.onCloseModal}
                        fileName={locatie}
                    />

                    : ""
                }
            </React.Fragment>
        );
    }
}

export default withRouter(CardInfo);