import React, { Component } from 'react';
import Carousel1 from '../components/Carousel/OldHeaderCarousel';
import LinksBar from '../components/links';
import InfoLista from '../components/InfoUtile/InfoUtileList';
import "core-js/modules/es6.promise";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/es6.array.from.js";
import "whatwg-fetch";
import "es6-map/implement";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsSection from '../components/Carousel/TopHomePageNews';
const { detect } = require('detect-browser');
const browser = detect();

class HomePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Carousel1 ></Carousel1>
        <section className="bg-light" >
          <div className="px-5">
            <div class="row">
              <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="left_content"></div>
               
                <div id="idInfoUtile">
                  <InfoLista style={{fontSize:'14 px'}} browserName={browser.name} browserVersion={browser.version} />
                </div>

              </div>
              <div className="border-left pb-3 col-lg-4 col-md-12 col-sm-12 ">
                <aside class="right_content ">
                  <NewsSection></NewsSection>
                </aside>
              </div>
            </div>
          </div>
        </section>

        <section  id="idLinkuriUtile">
          <LinksBar />
        </section>

      </React.Fragment >
    );
  }
}

export default HomePage;

