import React, { Component } from 'react';
import Title from '../components/Header/HeaderTitlePage';
import Anunt from '../components/anunt';
import axios from 'axios';

class MasuriCovid19 extends Component {
  state = {
    items: [],
  };
  componentDidMount = () => {
    /**
     * Get all files from a folder and create an array of objects
     */

    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/InfoUtile/Masuri Covid19"
        }
      })
      .then(res => {

        let i = 0;

        for (i = 0; i < res.data.length; i++) {

          /* Extract data field from pdf name */
          let indexStop = res.data[i].indexOf('.pdf');
          let titluDoc = res.data[i].substring(0, indexStop);
          const object = Object.assign({ titlu: titluDoc, locatie: "InfoUtile/Masuri Covid19/" + res.data[i] });
          this.setState({ items: [...this.state.items, object] });
        }

      })
      .catch(e => {
        console.log("Eroare la deschiderea fișierului", e);
      });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <Title
          title="Plan de măsuri în perioada epidemiei cu Covid-19"
          page="Măsuri de prevenţie a epidemiei Covid-19"
          breadcrumbs={true}
        />

        <div className="my-5 container">
          <Anunt
            icon={require("../images/icons/list.png")}
            docs={this.state.items}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default MasuriCovid19;
